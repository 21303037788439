/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Modsel
		Date:		6th March 2018
		Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Modsel
		Date:		6th March 2018
		Author:		Edge Marketing Solutions

=========================================================================*/
/* [Global Template Styles] */
body {
  font-weight: 400;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

h1, .h1 {
  margin: 0 0 2rem 0;
  font-weight: 100;
  font-size: 3.4rem;
}

h2, .h2 {
  margin: 2rem 0;
  font-weight: 100;
  font-size: 2.8rem;
}

h3, .h3 {
  margin: 2rem 0;
  font-weight: 100;
  font-size: 1.75rem;
}

h4, .h4 {
  margin: 1.5rem 0;
  font-weight: 400;
  font-size: 1.25rem;
}

h5, .h5 {
  margin: 1.5rem 0;
  font-weight: 400;
  font-size: 1.25rem;
}

h6, .h6 {
  margin: 1.5rem 0 0.5rem 0;
  font-weight: 700;
  font-size: 1.4rem;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

img.img-lightbox {
  cursor: zoom-in;
}

img[data-lazy-src]:not(.lazy-image-loaded) {
  padding: 50% 0;
  display: block;
  background: url("/images/loading.gif") no-repeat center center;
}

.swiper-container {
  width: 100%;
}

.btn {
  border-radius: 0;
  white-space: normal;
}

.p-no-margin p {
  margin: 0;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 3.0rem;
  }
  h2 {
    font-size: 2.0rem;
  }
  h3 {
    font-size: 2.0rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.4rem;
  }
  h6 {
    font-size: 1.0rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  h1 {
    font-size: 3.0rem;
  }
  h2 {
    font-size: 2.0rem;
  }
  h3 {
    font-size: 2.0rem;
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.4rem;
  }
  h6 {
    font-size: 1.0rem;
  }
  .d-xs-none {
    display: none;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  padding: 1rem 2rem;
  width: 100%;
  background-color: white;
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
}

.header h6, .header .h6 {
  margin: 0;
  font-size: 1rem;
}

.header-shadow {
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.header-contents {
  display: flex;
}

.header-contents .h-logo {
  flex-basis: 250px;
}

.header-contents .h-logo img {
  max-width: 220px;
}

.header-contents .h-search {
  align-self: right;
}

.form-search .input-group-text {
  background-color: transparent;
  border-right: none !important;
  border-radius: 0;
  cursor: pointer;
}

.form-search .form-control {
  border-left: none;
  border-radius: 0;
}

.h-logo img {
  max-width: 220px;
}

.topnav {
  width: 100%;
  padding: 0.3rem 2rem;
  font-size: 0.8rem;
  text-align: right;
}

/* Large desktop */
@media (min-width: 1200px) {
  .header {
    position: fixed;
    top: 0;
    z-index: 1000;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .header {
    position: fixed;
    top: 0;
    z-index: 1000;
  }
}

/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 2rem 0.25rem;
  }
  .header h6 {
    margin: 1rem 0;
  }
  .header .h-logo img {
    max-width: 300px;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar {
  padding: 0;
}

.navbar-light .navbar-nav .nav-link {
  padding: 0 1rem;
  font-weight: 400;
  font-size: .9rem;
  text-transform: uppercase;
}

.navbar-light .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-light .nav-item:last-child .nav-link {
  padding-right: 0;
}

.dropdown-submenu .dropdown-menu {
  background-color: #f3f3f3;
}

.dropdown-submenu .dropdown-menu .dropdown-item:hover {
  border-left: 0;
}

.navbar .dropdown-menu {
  border-radius: 0;
  margin: 0;
  padding: 0;
}

.navbar-light .dropdown .dropdown-menu.collapsing {
  display: block;
}

@media (min-width: 768px) {
  .dropdown-hover:hover > .dropdown-menu {
    display: block;
    margin-top: 0;
  }
  .dropdown-hover .dropdown-menu {
    border-color: #d9d9d9;
  }
  .dropdown-hover .dropdown-item {
    position: relative;
    border-top: 2px solid #f3f3f3;
  }
  .dropdown-hover .dropdown-submenu.dropdown-hover {
    position: static;
  }
  .dropdown-hover .dropdown-submenu.dropdown-hover .dropdown-menu {
    top: -1px;
    left: 0;
    min-height: calc(100% + 2px);
    width: 200%;
    background-color: #fff;
    z-index: -1;
  }
  .dropdown-hover .dropdown-submenu.dropdown-hover .dropdown-menu > div {
    position: relative;
    left: 50%;
    width: 50%;
    min-height: 100%;
    background-color: #f3f3f3;
    z-index: -1;
  }
  .dropdown-hover .dropdown-submenu.dropdown-hover .dropdown-menu .dropdown-item {
    border-top: 2px solid #E5E5E5;
  }
  .dropdown-hover .dropdown-submenu.dropdown-hover:hover > .dropdown-item::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.5rem;
    height: 100%;
  }
  .dropdown-hover .dropdown-submenu.dropdown-hover:hover > .dropdown-item {
    background-color: rgba(109, 110, 113, 0.1);
  }
  .dropdown-hover .dropdown-submenu.dropdown-hover:hover > .active {
    color: #6d6e71;
  }
  .dropdown-hover .dropdown-submenu.dropdown-hover:hover .dropdown-menu {
    display: flex;
  }
  .dropdown.dropdown-hover > .dropdown-menu > :first-child {
    border-top: none;
  }
  .dropdown.dropdown-hover > .dropdown-menu > :first-child > .dropdown-item {
    border-top: none;
  }
  .dropdown-menu {
    min-width: 220px;
  }
  .dropdown-item {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
    white-space: normal;
  }
  .dropdown-submenu {
    position: relative;
  }
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: -1px;
    transform: none;
    display: none;
  }
}

/* Large desktop */
@media (min-width: 1200px) {
  .navbar {
    margin-left: 1rem;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-light .navbar-nav .nav-link {
    padding: 0 0.5rem;
    font-size: 0.8rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-light {
    margin: 1.5rem 0;
  }
  .navbar-light .navbar-collapse {
    justify-content: center;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-light {
    margin: 1.0rem 0;
  }
  .navbar-light .navbar-toggler {
    width: 100%;
    border-radius: 0;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 1rem 0;
    text-align: center;
  }
  .navbar-nav .dropdown-menu {
    margin: 0;
    padding: 0;
    border: none;
  }
  .dropdown-menu .dropdown-submenu .dropdown-menu .dropdown-item {
    border-top: 0.5rem solid #f3f3f3 !important;
    border-bottom: 0.5rem solid #f3f3f3 !important;
    border-left: 1rem solid #f3f3f3 !important;
    border-right: 1rem solid #f3f3f3 !important;
  }
  .dropdown-menu .img-fluid {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 25%;
    max-width: 100px;
  }
  .dropdown-item {
    position: relative;
    padding: 1.75rem 5rem;
    text-align: center;
    font-size: 0.9rem;
    white-space: normal;
    word-wrap: normal;
    background-color: white;
    border-top: none !important;
    border-bottom: 1px solid #f3f3f3;
  }
}

/* Custom Small Mobile Styles */
@media (max-width: 400px) {
  .dropdown-menu .img-fluid {
    height: 77px;
    width: 77px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem 2rem 4rem 2rem;
}

.page hr {
  display: none;
}

.second-body h1, .second-body .h1 {
  font-size: 1.25rem;
  margin: 1rem 0;
}

.second-body h2, .second-body .h2, .second-body h3, .second-body .h3 {
  font-size: 1.125rem;
  margin: 1.5rem 0 0.5rem;
}

.nav-subnav {
  width: 90%;
  margin-bottom: 3rem;
}

.nav-subnav .nav-link {
  padding-left: 0;
  font-size: 1.125rem;
}

.page-title h1 {
  margin: 2.3rem 0 3.6rem 0;
  line-height: 1.0;
}

.breadcrumb {
  padding: 0;
  margin: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 0.8rem;
}

.breadcrumb .breadcrumb-item.active {
  opacity: 0.7;
}

.subpage-banner {
  position: relative;
  z-index: 1;
}

.subpage-banner img {
  width: 100%;
}

.subpage-banner::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.swiper-container {
  position: relative;
}

.swiper-slide-image {
  width: calc(100% - 24px);
}

.swiper-slide-image img {
  width: 100%;
}

.swiper-text {
  position: absolute;
  box-sizing: border-box;
  z-index: 3000;
  right: 8%;
  top: 50%;
  width: 414px;
  padding: 10px 50px 10px 30px;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}

.swiper-text::before {
  position: absolute;
  content: ' ';
  top: -93px;
  left: 0;
  width: 100%;
  height: 94px;
  background-repeat: no-repeat;
}

.swiper-text::after {
  position: absolute;
  content: ' ';
  bottom: -123px;
  left: 0;
  width: 100%;
  height: 124px;
  background-repeat: no-repeat;
}

.swiper-text .title {
  margin: 0 0 0.75rem 0;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.9em;
  text-align: right;
}

.swiper-text .subtitle {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.1rem;
  text-align: right;
}

.product-finder {
  margin-right: 10%;
}

.product-finder-front {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-right: 0;
}

.product-finder h3 {
  margin: 0;
  width: 100%;
  padding: 1rem 1.1rem;
  display: block;
  color: white;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1em;
}

.product-finder-front h4,
.product-finder-front .h4 {
  margin: 0;
  flex-basis: 20%;
  width: 20%;
  padding: 12px 40px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.product-finder .filter {
  padding: 0.75rem;
}

.product-finder-front .filter {
  padding: 12px 40px;
  flex-grow: 1;
}

.product-finder .filter form {
  display: flex;
  flex-wrap: wrap;
}

.product-finder .filter form .form-group {
  margin: 0.5rem;
  flex-grow: 1;
}

.product-finder .filter form .form-group .form-control {
  border-radius: 0;
  border: none;
}

.product-finder .filter small {
  margin: 0 0.5rem;
}

.product-finder.article-finder {
  background: none;
}

.product-finder.article-finder .filter form .form-group {
  padding: 0.5rem;
  margin: 0;
}

.product-finder.article-finder .filter form .form-group .form-control {
  border: 1px solid;
}

.product-finder.article-finder .btn-light {
  border: 1px solid;
}

.btn-primary .fa-caret-right {
  color: white;
}

.feature-nav {
  margin-bottom: 3rem;
  position: relative;
}

.feature-subpage {
  margin-bottom: 3rem;
  position: relative;
  margin-right: 10%;
}

.feature-nav .feature-item {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 2rem;
  height: calc(100% - 2rem);
  padding: 2rem 2.3rem 2rem 2.3rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
}

.feature-nav .feature-item .content h4 {
  margin: 0 0 1rem 0;
  font-size: 1.6rem;
  color: white;
}

.feature-nav .feature-item a {
  display: block;
  width: 100%;
  padding: 1rem 2.3rem;
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}

.feature-nav .feature-item a:hover {
  text-decoration: none;
}

.has-background-image:before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: rgba(31, 35, 32, 0.5);
}

.has-background-image:hover:before {
  background-color: rgba(31, 35, 32, 0.7);
}

.feature-nav .feature-item .fa {
  color: white;
}

.page-homepage h1:not(.h2, .h3, .h4, .h5, .h6),
.page-homepage .h1:not(.h2, .h3, .h4, .h5, .h6) {
  font-weight: 300;
  font-size: 3rem;
}

.homepage-feature-image {
  position: relative;
}

.homepage-feature-image::after {
  content: ' ';
  position: absolute;
  width: 101%;
  height: 101%;
  top: -1px;
  left: -1px;
  background-repeat: no-repeat;
}

.contact-table {
  margin-top: 3.8rem;
}

.csv-table .table {
  max-width: 700px;
}

.page-product .btn-action {
  display: block;
  margin: 1.5rem 0;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.page-product .btn-action:hover {
  text-decoration: none;
}

.page-product .video-border {
  padding: 0.25rem;
}

.page-product .card {
  margin-top: 1rem;
  border: none;
  border-radius: 0;
}

.page-product .card .card-body {
  padding: 3rem;
  background-color: transparent;
}

.page-product .card h4 {
  margin-top: 0;
}

.page-product .product-actions-card {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
}

.page-product .card-footer {
  padding: 0;
  border: none;
  background: none;
}

.page-product .card-footer .bg-button {
  background-color: rgba(0, 0, 0, 0.13);
}

.page-product .card-footer a {
  color: black;
}

.page-product .card-footer .btn-action {
  display: inline-block;
  margin: 0;
  padding: 0.75rem 0;
  font-size: 0.8rem;
  border-left: 2px solid white;
}

.page-product .card-footer .btn-action:hover {
  color: white;
  text-decoration: none;
}

.page-product .card-footer .btn-action:hover .fa {
  color: white;
}

.page-product .card-footer.justified .btn-action {
  text-align: center;
  width: calc(calc(100% / 3) - 4px);
}

.page-product .card-footer.justified .btn-action:first-child {
  border: none;
}

.page-product .card-footer.product-actions-footer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}

.page-product .card-footer.product-actions-footer > * {
  flex: 1 1 calc(33% - 8px);
  margin: 0 2px 6px;
}

.page-product .card-footer.product-actions-footer .btn-action {
  white-space: nowrap;
  text-align: center;
}

.page-product .sidebar {
  width: 90%;
  margin-bottom: 3rem;
  padding-right: 10%;
}

.page-product .sidebar small {
  display: block;
  margin-bottom: 2rem;
}

.page-product .sidebar h2,
.page-product .sidebar h3,
.page-product .sidebar h4,
.page-product .sidebar h6 {
  margin: 1.5rem 0 0.5rem 0;
  font-size: 1.2rem;
  font-weight: 400;
}

.page-product .sidebar .product-intro {
  margin-bottom: 2rem;
  display: block;
  font-size: 1.1rem;
}

.listing {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-content: flex-end;
}

.listing .listing-details {
  padding: 0.25rem 0.25rem 0 0.25rem;
}

.listing .listing-image {
  align-self: stretch;
}

.listing .listing-image img {
  width: 100%;
  padding: 0.3rem 0.3rem 0 0.3rem;
  background-color: rgba(0, 0, 0, 0.06);
}

.listing .listing-image a {
  display: flex;
  height: 100%;
  align-items: center;
  background-color: white;
}

.listing .listing-content {
  padding: 1.5rem 2rem 1rem 0;
  flex: 1 1 auto;
}

.listing h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.listing h3.article-listing-title {
  font-weight: 300;
  font-size: 1.25rem;
}

.listing h3 a:hover {
  text-decoration: none;
}

.listing p {
  display: block;
  margin: 1rem 0 0 0;
  font-size: 0.9rem;
}

.listing .listing-actions {
  background-color: rgba(0, 0, 0, 0.03);
}

.listing .listing-actions .flex-wrap .col {
  flex-basis: 50%;
}

.listing .listing-actions .flex-wrap .col .btn {
  white-space: nowrap;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.listing .listing-actions .btn:hover {
  color: #ffffff;
}

.blog-listing .listing-details {
  padding: 0.25rem 19px;
}

.blog-listing .listing-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat no-repeat;
}

.blog-listing .listing-image a {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent !important;
}

.gallery-image-container h5 {
  font-size: .9rem;
  font-weight: 500;
  margin: .5rem 0 .25rem;
}

.gallery-image-container p {
  font-size: .75rem;
  margin: .25rem 0 .5rem;
}

.fancybox-container.fancy-gallery .fancybox-navigation .fancybox-button {
  opacity: 1;
  visibility: visible;
}

.fancybox-container.fancy-gallery .lightbox-heading {
  color: #ffffff;
  font-weight: 500;
  margin: 0 0 1rem;
}

.fancybox-container.fancy-gallery .fancybox-caption::before {
  top: 0;
  background: rgba(0, 0, 0, 0.4);
}

.fancybox-container.fancy-gallery .fancybox-caption::after {
  border: none;
}

@media (min-width: 992px) {
  .fancybox-container.fancy-gallery .fancybox-stage {
    width: 50%;
  }
  .fancybox-container.fancy-gallery .fancybox-caption {
    opacity: 1;
    visibility: visible;
    top: 50%;
    left: 50%;
    bottom: auto;
    width: 50%;
    padding-right: 5rem;
    transform: translateY(-50%);
    z-index: 99996;
  }
  .fancybox-container.fancy-gallery .fancybox-caption::before {
    background: none;
  }
  .fancybox-container.fancy-gallery .fancybox-caption::after {
    border: none;
  }
}

@media (min-width: 1200px) {
  .fancybox-container.fancy-gallery .fancybox-stage {
    width: 60%;
  }
  .fancybox-container.fancy-gallery .fancybox-caption {
    left: 60%;
    width: 40%;
  }
}

/* Large desktop */
@media (min-width: 1200px) {
  .swiper-container,
  .subpage-banner {
    margin-top: 128px;
  }
  .gallery .card {
    max-width: 600px;
  }
  .listing .listing-image {
    min-height: 250px;
  }
  .listing .listing-actions .flex-wrap .col {
    flex-basis: 33%;
  }
  .listing-actions .single-line-btn {
    width: 100%;
    padding: 0.75rem 0;
    border-bottom: 1px solid white !important;
    font-size: 0.7rem;
  }
  .listing-actions .btn-fullwidth {
    width: 100%;
    padding: 0.75rem 0;
    border-bottom: 1px solid white !important;
    font-size: 0.9rem;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .nav-subnav .nav-link {
    font-size: 1.1rem;
  }
  .swiper-container,
  .subpage-banner {
    margin-top: 128px;
  }
  .swiper-text {
    right: 8%;
    width: 350px;
    padding: 10px 50px 10px 30px;
  }
  .swiper-text::before {
    top: -73px;
    height: 74px;
  }
  .swiper-text::after {
    bottom: -103px;
    height: 104px;
  }
  .swiper-text .title {
    font-size: 1.8rem;
  }
  .product-finder-front {
    display: block;
  }
  .product-finder h3 {
    padding: 1rem;
    font-size: 1.1rem;
  }
  .product-finder-front h4,
  .product-finder-front .h4 {
    width: 100%;
    padding: 15px 40px 0;
    font-size: 1.8rem;
  }
  .product-finder .filter {
    padding: 0.5rem;
  }
  .product-finder-front .filter {
    padding: 15px 40px;
  }
  .listing h3 {
    font-size: 1.4rem;
  }
  .listing p {
    font-size: 0.8rem;
  }
  .listing-actions .btn {
    width: 100%;
    padding: 0.75rem 0;
    border-bottom: 1px solid white !important;
    font-size: 0.9rem;
  }
  .listing-actions .single-line-btn {
    width: 100%;
    padding: 0.75rem 0;
    border-bottom: 1px solid white !important;
    font-size: 0.6rem;
  }
  .listing-actions .btn-fullwidth {
    width: 100%;
    padding: 0.75rem 0;
    border-bottom: 1px solid white !important;
    font-size: 0.9rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .nav-subnav .nav-link {
    font-size: 1.0rem;
  }
  .product-finder-front h4,
  .product-finder-front .h4 {
    width: 100%;
    padding: 15px 40px 0;
    font-size: 1.8rem;
  }
  .swiper-text {
    right: 8%;
    width: 350px;
    padding: 10px 50px 10px 30px;
  }
  .swiper-text::before {
    top: -73px;
    height: 74px;
  }
  .swiper-text::after {
    bottom: -103px;
    height: 104px;
  }
  .swiper-text .title {
    font-size: 1.8rem;
  }
  .product-finder-front {
    display: block;
  }
  .product-finder h3 {
    padding: 1rem;
    font-size: 1.1rem;
  }
  .product-finder-front h3 {
    width: 100%;
    padding: 15px 40px;
    font-size: 1.8rem;
  }
  .product-finder .filter {
    padding: 0.5rem;
  }
  .product-finder-front .filter {
    padding: 15px 40px;
  }
  .feature-nav .feature-item {
    margin-bottom: 2rem;
    height: calc(100% - 2rem);
  }
  .feature-nav .feature-item .content {
    padding: 2rem 1.4rem 2rem 1.4rem;
  }
  .feature-nav .feature-item .content h4 {
    font-size: 1.4rem;
  }
  .feature-nav .feature-item a {
    padding: 1rem 1.4rem;
  }
  .listing h3 {
    font-size: 1.4rem;
  }
  .listing p {
    font-size: 0.8rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (max-width: 991px) {
  .product-finder-front h4,
  .product-finder-front .h4 {
    width: 100%;
    padding: 15px 40px 0;
  }
  .listing-actions .btn {
    width: 100%;
    padding: 0.75rem 0;
    border-bottom: 1px solid white !important;
    font-size: 0.9rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page {
    width: 100%;
    padding: 2rem 0.5rem;
  }
  .page-title h1 {
    margin: 2rem 0;
  }
  .nav-subnav {
    width: 100%;
    margin: 1.5rem 0;
  }
  .nav-subnav .nav-item:last-child {
    border-bottom: none;
  }
  .nav-subnav .nav-link {
    padding: 1rem 0.75rem;
    font-size: 1.0rem;
  }
  .swiper-slide-image {
    width: 95%;
  }
  .swiper-text {
    width: 95%;
    position: relative;
    display: block;
    right: 0;
    top: 0;
    padding: 30px 20px;
    transform: translateY(0);
    background-color: white;
    background-image: none;
  }
  .swiper-text .title {
    text-align: center;
  }
  .swiper-text .subtitle {
    text-align: center;
  }
  .swiper-pagination {
    position: relative;
    padding-top: 15px;
    width: 95%;
    background-color: white;
    margin-bottom: -14px;
  }
  .swiper-slide {
    background-color: white;
  }
  .swiper-container {
    background-color: white;
  }
  .swiper-text::before {
    display: none;
  }
  .swiper-text::after {
    display: none;
  }
  .product-finder {
    margin: 0;
  }
  .product-finder-front {
    display: block;
  }
  .product-finder h3 {
    padding: 1rem;
    font-size: 1.1rem;
  }
  .product-finder-front h3 {
    width: 100%;
    padding: 15px 40px;
    font-size: 1.1rem;
  }
  .product-finder .filter {
    padding: 0.5rem;
  }
  .product-finder-front .filter {
    padding: 15px 40px;
  }
  .feature-subpage {
    margin-right: 0;
  }
  .feature-nav .feature-item {
    margin-bottom: 2rem;
    height: calc(100% - 2rem);
  }
  .feature-nav .feature-item .content {
    padding: 0;
  }
  .feature-nav .feature-item .content h4 {
    font-size: 1.4rem;
  }
  .feature-nav .feature-item a {
    padding: 1rem 1.4rem;
  }
  .page-product .sidebar {
    width: 100%;
    margin-bottom: 3rem;
    padding-right: 0;
    border-right: 0 !important;
  }
  .page-product .card .card-body {
    padding: 1.5rem;
  }
  .page-product .card .card-footer .btn-action {
    display: block;
    width: 100%;
    border: none;
    border-top: 1px solid white;
    font-size: 0.9rem;
    text-align: center;
  }
  .listing .listing-content {
    padding: 1.5rem 1.5rem;
  }
  .blog-listing .listing-image {
    height: 15rem;
  }
}

/*=========================================================================*/
/* [Section Styles] */
.page-sections {
  --section-gap: 2rem;
  display: flex;
  padding: 0 0 var(--section-gap);
  gap: var(--section-gap);
  flex-wrap: wrap;
}

.page-sections:first-child {
  padding-top: 0;
}

.page-sections:last-child {
  padding-bottom: 0;
}

.page-sections .section {
  padding: 2rem;
}

.page-sections .section h1:first-child, .page-sections .section .h1:first-child, .page-sections .section h2:first-child, .page-sections .section .h2:first-child, .page-sections .section h3:first-child, .page-sections .section .h3:first-child, .page-sections .section h4:first-child, .page-sections .section .h4:first-child, .page-sections .section h5:first-child, .page-sections .section .h5:first-child, .page-sections .section h6:first-child, .page-sections .section .h6:first-child {
  margin-top: 0;
}

.page-sections .section > div :last-child {
  margin-bottom: 0;
}

.page-sections .section.section-full {
  width: 100%;
}

.page-sections .section.section-full:not(.section-grey):not(.section-pink):not(.section-blue):not(.section-purple):not(.section-bg-image) {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.page-sections .section.section-img-left, .page-sections .section.section-img-right {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.page-sections .section.section-img-left > *, .page-sections .section.section-img-right > * {
  flex-basis: calc(100% / 2);
}

.page-sections .section.section-img-left > :last-child, .page-sections .section.section-img-right > :last-child {
  padding: 3rem;
}

.page-sections .section.section-img-right {
  flex-direction: row-reverse;
}

.page-sections .section.section-bg-image {
  color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-sections .section.section-bg-image h1, .page-sections .section.section-bg-image .h1, .page-sections .section.section-bg-image h2, .page-sections .section.section-bg-image .h2, .page-sections .section.section-bg-image h3, .page-sections .section.section-bg-image .h3, .page-sections .section.section-bg-image h4, .page-sections .section.section-bg-image .h4, .page-sections .section.section-bg-image h5, .page-sections .section.section-bg-image .h5, .page-sections .section.section-bg-image h6, .page-sections .section.section-bg-image .h6 {
  color: #fff;
}

.page-sections .section.section-bg-image .btn.btn-outline-dark {
  color: #fff;
  border-color: #fff;
}

.page-sections .section.section-bg-image .btn.btn-outline-dark:hover {
  background-color: #fff;
}

.page-sections .section.section-image-overlay {
  position: relative;
  color: #fff;
}

.page-sections .section.section-image-overlay h1, .page-sections .section.section-image-overlay .h1, .page-sections .section.section-image-overlay h2, .page-sections .section.section-image-overlay .h2, .page-sections .section.section-image-overlay h3, .page-sections .section.section-image-overlay .h3, .page-sections .section.section-image-overlay h4, .page-sections .section.section-image-overlay .h4, .page-sections .section.section-image-overlay h5, .page-sections .section.section-image-overlay .h5, .page-sections .section.section-image-overlay h6, .page-sections .section.section-image-overlay .h6 {
  color: #fff;
}

.page-sections .section.section-image-overlay:before {
  content: " ";
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-sections .section.section-image-overlay > div {
  position: relative;
}

/* Large desktop */
@media (min-width: 1200px) {
  .page-sections .section.section-half {
    flex-basis: calc((100% - var(--section-gap)) / 2);
  }
  .page-sections .section.section-third {
    padding: 2rem;
    flex-basis: calc((100% - var(--section-gap) * 2) / 3);
  }
  .page-sections .section.section-quarter {
    padding: 2rem;
    flex-basis: calc(100% / 4);
  }
  .page-sections .section.section-quarter + .section-quarter {
    margin-left: calc(var(--section-gap) * -1);
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .page-sections .section.section-img-left > :last-child, .page-sections .section.section-img-right > :last-child {
    padding: 2rem;
  }
  .page-sections .section.section-half {
    flex-basis: calc((100% - var(--section-gap)) / 2);
  }
  .page-sections .section.section-quarter {
    flex-basis: calc(100% / 2);
  }
  .page-sections .section.section-quarter + .section-quarter {
    margin-left: calc(var(--section-gap) * -1);
  }
  .page-sections .section.section-quarter + .section-quarter + .section-quarter {
    margin-left: 0;
    margin-top: calc(var(--section-gap) * -1);
  }
  .page-sections .section.section-quarter + .section-quarter + .section-quarter + .section-quarter {
    margin-left: calc(var(--section-gap) * -1);
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .page-sections .section.section-img-left, .page-sections .section.section-img-right {
    flex-direction: column;
  }
  .page-sections .section.section-img-left > :first-child, .page-sections .section.section-img-right > :first-child {
    aspect-ratio: 16 / 9;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page-sections .section {
    width: 100%;
    padding: 1.25rem;
  }
  .page-sections .section.section-img-left, .page-sections .section.section-img-right {
    flex-direction: column;
  }
  .page-sections .section.section-img-left > :first-child, .page-sections .section.section-img-right > :first-child {
    aspect-ratio: 16 / 9;
  }
  .page-sections .section.section-bg-image {
    min-height: 45vw;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer-action {
  width: 100%;
  padding: 2rem 0;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: white;
}

.footer-action::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.footer-action .btn-secondary {
  padding: 1rem 1rem;
  background-color: transparent;
  border-color: white;
  font-weight: 300;
}

.footer-action .fa {
  font-weight: normal;
}

.footer-action .btn:hover .fa {
  color: white;
}

.footer {
  width: 100%;
  position: relative;
  padding: 4.5rem 2rem 2rem 2rem;
  font-size: 0.8rem;
}

.footer::after {
  position: absolute;
  width: 100%;
  height: 10px;
  content: ' ';
  bottom: 0;
  left: 0;
}

.footer .f-logo img {
  max-width: 160px;
}

.footer .footnotes {
  padding-top: 3rem;
  font-size: 0.7rem;
}

.footer .f-social {
  text-align: right;
  font-size: 2rem;
  line-height: 1.0;
}

.footer h6,
.footer .h6 {
  margin: 0 0 0.5rem 0;
  font-size: 0.8rem;
}

.footer h6 a,
.footer .h6 a {
  text-decoration: none;
}

.footer .nav {
  display: block;
}

.footer .nav .nav-link {
  padding: 0.2rem 0;
}

.footer .btn-primary {
  margin-bottom: 3rem;
  color: white !important;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer-action {
    padding: 2rem 0.5rem;
  }
  .footer-action .btn {
    margin: 1rem 0;
  }
  .footer {
    padding: 2rem 0.5rem 2rem 0.5rem;
  }
  .footer .f-social {
    padding-top: 2rem;
    text-align: left;
  }
  .footer .col {
    flex-basis: 50%;
  }
  .footer .nav {
    margin: 2rem 0 0 0;
  }
  .footer .f-logo img {
    max-width: 220px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer-action {
    padding: 2rem 0.5rem;
  }
  .footer-action .btn {
    margin: 1rem 0;
  }
  .footer {
    padding: 2rem 0.5rem 2rem 0.5rem;
  }
  .footer .f-social {
    padding-top: 2rem;
    text-align: left;
  }
  .footer .col {
    flex-basis: 50%;
  }
  .footer .nav {
    margin: 2rem 0 0 0;
  }
  .footer .f-logo img {
    max-width: 220px;
  }
}

/*=========================================================================*/
/* [Nav Search Styles] */
.nav-search-results-wrapper {
  position: relative;
}

.nav-search-results-wrapper .nav-search-results-container {
  background: #ffffff;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.nav-search-results-wrapper .nav-search-results-container .spinner {
  margin: 20px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.nav-search-results-wrapper .nav-search-results-container .spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.nav-search-results-wrapper .nav-search-results-container .spinner > div.rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.nav-search-results-wrapper .nav-search-results-container .spinner > div.rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.nav-search-results-wrapper .nav-search-results-container .spinner > div.rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.nav-search-results-wrapper .nav-search-results-container .spinner > div.rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.nav-search-results-wrapper .nav-search-results-container .nav-search-result a {
  display: block;
  padding: .5rem .75rem;
  font-size: .9rem;
  position: relative;
}

.nav-search-results-wrapper .nav-search-results-container .nav-search-result a .nav-search-image-col {
  max-width: calc(60px + .5rem);
  padding-right: .5rem;
}

.nav-search-results-wrapper .nav-search-results-container .nav-search-result a .nav-search-image-col img {
  width: 60px;
}

.nav-search-results-wrapper .nav-search-results-container .nav-search-result a:hover {
  text-decoration: none;
  background-color: rgba(109, 110, 113, 0.1);
}

.nav-search-results-wrapper .nav-search-results-container .nav-search-result a:hover::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 0.5rem;
  height: 100%;
}

.nav-search-results-wrapper .nav-search-results-container .btn {
  border: 1px solid #ffffff;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* col-lg */
/* col-md */
/* col-sm */
/* col-xs */
/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}

@font-face {
  font-family: 'Gellix';
  src: url("/fonts/Gellix-Medium.woff2") format("woff2"), url("/fonts/Gellix-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gellix';
  src: url("/fonts/Gellix-SemiBold.woff2") format("woff2"), url("/fonts/Gellix-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gellix';
  src: url("/fonts/Gellix-Bold.woff2") format("woff2"), url("/fonts/Gellix-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

/*=========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
/*=========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #6d6e71;
  font-family: "Gellix", sans-serif;
}

a {
  color: #ee3e7b;
}

h1, .h1 {
  color: #182859;
}

h2, .h2 {
  color: #182859;
}

h3, .h3 {
  color: #ee3e7b;
}

h4, .h4 {
  color: #182859;
}

h5, .h5 {
  color: #ee3e7b;
}

h6, .h6 {
  color: #182859;
}

.btn-primary {
  background-color: #182859;
  border-color: #182859;
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #ee3e7b;
  border-color: #ee3e7b;
}

.btn-secondary {
  background-color: #ee3e7b;
  border-color: #ee3e7b;
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #182859;
  border-color: #182859;
}

.btn-outline-secondary {
  color: #ee3e7b;
  background-color: #ffffff;
  border-color: #ee3e7b;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus {
  color: #182859;
  background-color: #ffffff;
  border-color: #182859;
}

.btn-light {
  border-color: transparent;
  background-color: transparent;
}

.btn-light:hover,
.btn-light:focus {
  background-color: #dde4e8;
  border-color: #dde4e8;
}

.specials {
  position: absolute;
  top: 10px;
  left: 15px;
  padding: 0.2rem 2rem 0.3rem 0.4rem;
  min-width: 100px;
  width: max-content;
  height: 30px;
  color: white;
  background-color: #ee3e7b;
  -webkit-clip-path: polygon(100% 0, 85% 50%, 100% 100%, 0% 100%, 0 49%, 0% 0%);
  clip-path: polygon(100% 0, 85% 50%, 100% 100%, 0% 100%, 0 49%, 0% 0%);
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Portrait tablet to landscape and desktop */
@media (max-width: 991px) {
  .listing-actions .btn {
    color: #182859;
  }
  .listing-actions .btn .fa {
    color: #ee3e7b;
  }
  .listing-actions .btn.btn-secondary {
    color: #ffffff;
  }
  .listing-actions .btn:hover .fa {
    color: #ffffff;
  }
}

/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Header Template Styles] */
.header h6, .header .h6 {
  color: #6d6e71;
}

.header h6 a, .header .h6 a {
  color: #6d6e71;
}

.form-search .input-group-text {
  border-color: #ee3e7b;
}

.form-search .form-control {
  border-color: #ee3e7b;
}

.topnav {
  border-bottom: 1px solid rgba(109, 110, 113, 0.2);
  color: rgba(109, 110, 113, 0.2);
}

.topnav a {
  color: rgba(109, 110, 113, 0.6);
}

.topnav a:hover {
  color: #182859;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-light .navbar-nav .nav-link {
  color: #6d6e71;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #ee3e7b;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #182859;
}

.dropdown-hover .dropdown-submenu.dropdown-hover:hover > .dropdown-item::before {
  background-color: #182859;
}

@media (min-width: 768px) {
  .dropdown-item {
    color: #6d6e71;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: rgba(109, 110, 113, 0.1);
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #182859;
  }
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-light .navbar-nav .nav-link {
    padding: 0 0.8rem;
  }
}

/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-light .navbar-toggler {
    border-color: #182859;
  }
  .navbar-light .navbar-collapse {
    border: 1px solid #182859;
  }
  .navbar-light .navbar-nav .nav-link {
    border-bottom: 1px solid rgba(109, 110, 113, 0.2);
  }
  .navbar-nav .dropdown-menu {
    background-color: rgba(109, 110, 113, 0.1);
  }
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: #182859;
  }
  .dropdown-item:hover {
    background-color: #182859;
    color: white;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.nav-subnav {
  border-right: 1px solid #182859;
}

.nav-subnav .nav-link {
  text-transform: capitalize;
  color: #6d6e71;
}

.nav-subnav .nav-item.active .nav-link {
  color: #ee3e7b;
}

.page-title h1 {
  text-transform: capitalize;
}

.breadcrumb {
  text-transform: capitalize;
}

.breadcrumb .breadcrumb-item a {
  color: #6d6e71;
}

.subpage-banner::after {
  background-color: rgba(24, 40, 89, 0.35);
  border-right: 24px solid #182859;
}

.swiper-slide {
  background-color: #182859;
}

.swiper-text {
  background-image: url("/images/modsel/rotator-text-body.svg");
}

.swiper-text::before {
  background-image: url("/images/modsel/rotator-text-top.svg");
}

.swiper-text::after {
  background-image: url("/images/modsel/rotator-text-bottom.svg");
}

.swiper-text .title {
  color: #ee3e7b;
}

.swiper-text .subtitle {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2.1rem;
}

.product-finder {
  background-color: #dde4e8;
}

.product-finder .btn-primary {
  background-color: #59595b;
  border-color: #59595b;
}

.product-finder .btn-primary:hover {
  background-color: #ee3e7b;
}

.product-finder.article-finder .filter form .form-group .form-control {
  border-color: #ee3e7b;
}

.product-finder.article-finder .btn-light {
  color: #ee3e7b;
  border-color: #ee3e7b;
}

.product-finder-front {
  background-color: #dde4e8;
}

.product-finder h3 {
  background-color: #ee3e7b;
}

.btn-primary .fa-caret-right {
  color: #ee3e7b;
}

.feature-nav .feature-item .fa {
  color: #ee3e7b;
}

.page-homepage h1:not(.h2, .h3, .h4, .h5, .h6),
.page-homepage .h1:not(.h2, .h3, .h4, .h5, .h6) {
  color: #ee3e7b;
}

.homepage-feature-image::after {
  background-image: url("/images/modsel/homepage-feature-overlay.svg");
}

.page-product h1 {
  text-transform: capitalize;
}

.page-product .btn-action {
  color: #182859;
  font-size: 1.2rem;
}

.page-product .btn-action .fa {
  color: #ee3e7b;
}

.page-product .btn-action .fa:hover {
  color: white !important;
}

.page-product .video-border {
  background-color: #dde4e8;
}

.page-product .video-action {
  color: #6d6e71;
}

.page-product .video-action .fa {
  color: #ee3e7b;
}

.page-product .card {
  margin-top: 3rem;
}

.page-product .card-footer .btn-action {
  padding: 0.75rem 2rem;
}

.page-product .card-footer .btn-action:hover {
  background-color: #ee3e7b;
}

.page-product .sidebar {
  border-right: 1px solid #182859;
}

.page-product .sidebar h2 {
  color: #ee3e7b;
}

.listing {
  background-color: #dde4e8;
}

.listing h3 {
  text-transform: capitalize;
}

.listing h3 a:hover {
  color: #ee3e7b;
}

.listing .listing-actions .btn {
  color: #182859;
}

.listing .listing-actions .btn .fa {
  color: #ee3e7b;
}

.listing .listing-actions .btn:hover {
  color: #ffffff;
  background: #ee3e7b;
}

.listing .listing-actions .btn:hover .fa {
  color: #ffffff;
}

.listing .listing-actions .btn-secondary {
  color: #ffffff;
}

.listing .listing-actions .btn-secondary:hover {
  border-color: #ee3e7b;
}

.gallery-image-container h5 {
  color: #ee3e7b;
}

.download-hover:hover {
  color: white !important;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .nav-subnav {
    border: 1px solid rgba(109, 110, 113, 0.3);
  }
  .nav-subnav .nav-item {
    border-bottom: 1px solid rgba(109, 110, 113, 0.3);
  }
  .subpage-banner::after {
    border-right: 10px solid #182859;
  }
  .swiper-text .title {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.8rem;
  }
  .swiper-text .subtitle {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.6rem;
    color: #22242A;
  }
}

/*=========================================================================*/
/* [Section Styles] */
.page-sections .section .btn.btn-outline-dark {
  color: #6d6e71;
  border-color: #6d6e71;
}

.page-sections .section .btn.btn-outline-dark:hover {
  color: #fff;
  background-color: #6d6e71;
}

.page-sections .section.section-grey {
  background-color: #dde4e8;
}

.page-sections .section.section-light-blue {
  background-color: #c7eafb;
}

.page-sections .section.section-blue {
  background-color: #182859;
  color: #fff;
}

.page-sections .section.section-blue h1, .page-sections .section.section-blue .h1, .page-sections .section.section-blue h2, .page-sections .section.section-blue .h2, .page-sections .section.section-blue h3, .page-sections .section.section-blue .h3, .page-sections .section.section-blue h4, .page-sections .section.section-blue .h4, .page-sections .section.section-blue h5, .page-sections .section.section-blue .h5, .page-sections .section.section-blue h6, .page-sections .section.section-blue .h6 {
  color: #fff;
}

.page-sections .section.section-blue .btn.btn-outline-dark {
  color: #fff;
  border-color: #fff;
}

.page-sections .section.section-blue .btn.btn-outline-dark:hover {
  color: #182859;
  background-color: #fff;
}

.page-sections .section.section-light-purple {
  background-color: #facad0;
}

.page-sections .section.section-purple {
  background-color: #ee3e7b;
  color: #fff;
}

.page-sections .section.section-purple h1, .page-sections .section.section-purple .h1, .page-sections .section.section-purple h2, .page-sections .section.section-purple .h2, .page-sections .section.section-purple h3, .page-sections .section.section-purple .h3, .page-sections .section.section-purple h4, .page-sections .section.section-purple .h4, .page-sections .section.section-purple h5, .page-sections .section.section-purple .h5, .page-sections .section.section-purple h6, .page-sections .section.section-purple .h6 {
  color: #fff;
}

.page-sections .section.section-purple .btn.btn-outline-dark {
  color: #fff;
  border-color: #fff;
}

.page-sections .section.section-purple .btn.btn-outline-dark:hover {
  color: #ee3e7b;
  background-color: #fff;
}

.page-sections .section.section-bg-image .btn.btn-outline-dark:hover {
  color: #6d6e71;
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer-action::after {
  background-color: rgba(24, 40, 89, 0.8);
}

.footer-action .btn-secondary:hover,
.footer-action .btn-secondary:focus {
  background-color: #ee3e7b;
  border-color: #ee3e7b;
}

.footer-action .fa {
  color: #ee3e7b;
}

.footer {
  text-transform: capitalize;
}

/*.footer::after
		{
			background-image: url('/images/modsel/footer-element.svg');
			background-repeat: no-repeat;
		}*/
.footer a {
  color: #6d6e71;
}

.footer a:hover {
  color: #182859;
}

.footer .f-social a {
  color: #182859;
}

.footer h6 a,
.footer .h6 a {
  color: #182859;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Nav Search Styles] */
.nav-search-results-wrapper .nav-search-results-container .nav-search-result a {
  border: 1px solid #dde4e8;
  border-top: none;
}

.nav-search-results-wrapper .nav-search-results-container .nav-search-result a:hover::before {
  background: #182859;
}

.nav-search-results-wrapper .nav-search-results-container .nav-search-result.nav-search-page-result {
  background: #dde4e8;
}

.nav-search-results-wrapper .nav-search-results-container .nav-search-result.nav-search-page-result a {
  border-bottom-color: #ffffff;
}
