@font-face {
    font-family: 'Gellix';
    src: url("/fonts/Gellix-Medium.woff2") format("woff2"), url("/fonts/Gellix-Medium.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url("/fonts/Gellix-SemiBold.woff2") format("woff2"), url("/fonts/Gellix-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gellix';
    src: url("/fonts/Gellix-Bold.woff2") format("woff2"), url("/fonts/Gellix-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

/*=========================================================================*/

/* [Global Variables] - http://sass-lang.com/guide */

$color-purple: rgb(238, 62, 123);
$color-purple-light: rgb(250, 202, 208);
$color-blue-dark: rgb(24, 40, 89);
$color-blue-light: rgb(199, 234, 251);
$color-grey-dark: rgb(89, 89, 91);
$color-grey-med: rgb(109, 110, 113);
$color-grey-light: rgb(221, 228, 232);

$color-grey-light-pdf: rgb(201, 202, 202);

$font-default: 'Gellix', sans-serif;

/*=========================================================================*/
