/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Modsel
		Date:		6th March 2018
		Author:		Edge Marketing Solutions

===========================================================================*/

	/* [Global Variables] - http://sass-lang.com/guide */

	@import '../default';
	@import 'variables';

/*=========================================================================*/

	/* [Global Template Styles] */

	body {
		color: $color-grey-med;
		font-family: $font-default; // Accidentally removed so this is just here in case we need to reinstate it
	}

	a
	{
		color: $color-purple;
	}

	h1, .h1
	{
		color: $color-blue-dark;
	}

	h2, .h2
	{
		color: $color-blue-dark;
	}

	h3, .h3
	{
		color: $color-purple;
	}

	h4, .h4
	{
		color: $color-blue-dark;
	}

	h5, .h5
	{
		color: $color-purple;
	}

	h6, .h6
	{
		color: $color-blue-dark;
	}

	.btn-primary
	{
		background-color: $color-blue-dark;
		border-color: $color-blue-dark;
	}

		.btn-primary:hover,
		.btn-primary:focus
		{
			background-color: $color-purple;
			border-color: $color-purple;
		}

	.btn-secondary
	{
		background-color: $color-purple;
		border-color: $color-purple;
	}

		.btn-secondary:hover,
		.btn-secondary:focus
		{
			background-color: $color-blue-dark;
			border-color: $color-blue-dark;
		}

	.btn-outline-secondary
	{
		color: $color-purple;
		background-color: #ffffff;
		border-color: $color-purple;
	}

		.btn-outline-secondary:hover,
		.btn-outline-secondary:focus
		{
			color: $color-blue-dark;
			background-color: #ffffff;
			border-color: $color-blue-dark;
		}

	.btn-light
	{
		border-color: transparent;
		background-color: transparent;
	}

		.btn-light:hover,
		.btn-light:focus
		{
			background-color: $color-grey-light;
			border-color: $color-grey-light;
		}

	.specials {
		position: absolute;
		top: 10px;
		left: 15px;
		padding: 0.2rem 2rem 0.3rem 0.4rem;
		min-width: 100px;
		width: max-content;
		height: 30px;


		color: white;
		background-color: $color-purple;
		-webkit-clip-path: polygon(100% 0, 85% 50%, 100% 100%, 0% 100%, 0 49%, 0% 0%);
		clip-path: polygon(100% 0, 85% 50%, 100% 100%, 0% 100%, 0 49%, 0% 0%);
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (max-width: 991px) {

		.listing-actions {
			.btn {
				color: $color-blue-dark;

				.fa {
					color: $color-purple;
				}

				&.btn-secondary {
					color: #ffffff;
				}

				&:hover {
					.fa {
						color: #ffffff;
					}
				}
			}
		}

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{

		h6, .h6
		{
			color: $color-grey-med;

			a
			{
				color: $color-grey-med;
			}
		}
	}
			.header-contents .h-logo
			{

			}

			.form-search .input-group-text
			{
				border-color: $color-purple;
			}

			.form-search .form-control
			{
				border-color: $color-purple;
			}

	.topnav
	{
		border-bottom: 1px solid transparentize($color-grey-med, 0.8);
		color: transparentize($color-grey-med, 0.8);
	}

		.topnav a
		{
			color: transparentize($color-grey-med, 0.4);
		}

		.topnav a:hover
		{
			color: $color-blue-dark;
		}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-light .navbar-nav .nav-link
	{
		color: $color-grey-med;

	}

	.navbar-light .navbar-nav .show > .nav-link,
	.navbar-light .navbar-nav .active > .nav-link,
	.navbar-light .navbar-nav .nav-link.show,
	.navbar-light .navbar-nav .nav-link.active
	{
		color: $color-purple;
	}

	.navbar-light .navbar-nav .nav-link:hover,
	.navbar-light .navbar-nav .nav-link:focus
	{
		color: $color-blue-dark;

	}

	.dropdown-submenu .dropdown-menu .dropdown-item:hover {

	}

	.dropdown-hover {
		.dropdown-submenu.dropdown-hover:hover {
			> .dropdown-item::before {
				background-color: $color-blue-dark;


			}
		}
	}


	@media (min-width: 768px) {
		.dropdown-item
		{
			color: $color-grey-med;
		}

		.dropdown-item:hover,
		.dropdown-item:focus
		{
			background-color: transparentize($color-grey-med, 0.9);
		}

		.dropdown-item.active,
		.dropdown-item:active
		{
			background-color: $color-blue-dark;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.navbar-light .navbar-nav .nav-link
		{
			padding: 0 0.8rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

		.navbar-light .navbar-toggler
		{
			border-color: $color-blue-dark;
		}

		.navbar-light .navbar-collapse
		{
			border: 1px solid $color-blue-dark;
		}

		.navbar-light .navbar-nav .nav-link
		{
			border-bottom: 1px solid transparentize($color-grey-med, 0.8);
		}

		.navbar-nav .dropdown-menu
		{
			background-color: transparentize($color-grey-med, 0.9);
		}

		.dropdown-item.active,
		.dropdown-item:active
		{
			background-color: $color-blue-dark
		}

		.dropdown-item {

			&:hover {
				background-color: $color-blue-dark;
				color: white;

			}
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

		.nav-subnav
		{
			border-right: 1px solid $color-blue-dark;
		}

		.nav-subnav .nav-link
		{
			text-transform: capitalize;
			color: $color-grey-med;
		}

		.nav-subnav .nav-item.active .nav-link
		{
			color: $color-purple;
		}

	.page-title h1
	{
		text-transform: capitalize;
	}

	.breadcrumb
	{
		text-transform: capitalize;
	}

		.breadcrumb .breadcrumb-item a
		{
			color: $color-grey-med;
		}

		.subpage-banner::after
		{
			background-color: transparentize($color-blue-dark, 0.65);
			border-right: 24px solid $color-blue-dark;
		}

	.swiper-slide
	{
		background-color: $color-blue-dark;
	}



	.swiper-text
	{
		background-image: url('/images/modsel/rotator-text-body.svg');
	}

		.swiper-text::before
		{
			background-image: url('/images/modsel/rotator-text-top.svg');
		}


		.swiper-text::after
		{
			background-image: url('/images/modsel/rotator-text-bottom.svg');
		}

		.swiper-text .title
		{
			color: $color-purple;
		}

		.swiper-text .subtitle
		{
			font-size: 1.8rem;
			font-weight: 300;
			line-height: 2.1rem;
		}

	.product-finder
	{
		background-color: $color-grey-light;

		.btn-primary
		{
			background-color: $color-grey-dark;
			border-color: $color-grey-dark;
		}

		.btn-primary:hover
		{
			background-color: $color-purple;
		}
	}

	.product-finder.article-finder {
		.filter form {
			.form-group {
				.form-control {
					border-color: $color-purple;
				}
			}
		}

		.btn-light {
			color: $color-purple;
			border-color: $color-purple;
		}
	}

	.product-finder-front
	{
		background-color: $color-grey-light;
	}

		.product-finder h3
		{
			background-color: $color-purple;
		}

	.btn-primary .fa-caret-right
	{
		color: $color-purple;
	}


			.feature-nav .feature-item .fa
			{
				color: $color-purple;
			}

	.page-homepage h1:not(.h2, .h3, .h4, .h5, .h6),
	.page-homepage .h1:not(.h2, .h3, .h4, .h5, .h6)
	{
		color: $color-purple;
	}

		.homepage-feature-image::after
		{
			background-image: url('/images/modsel/homepage-feature-overlay.svg');
		}


	.page-product
	{
		h1
		{
			text-transform: capitalize;
		}

		.btn-action
		{
			color: $color-blue-dark;
			font-size: 1.2rem;

			.fa
			{
				color: $color-purple;

				&:hover {
					color: white !important;
				}
			}
		}



		.video-border
		{
			background-color: $color-grey-light;
		}

		.video-action
		{
			color: $color-grey-med;

			.fa
			{
				color: $color-purple;
			}
		}

		.card
		{
			margin-top: 3rem;
		}

			.card-footer
			{
				.btn-action
				{
					padding: 0.75rem 2rem;
				}

					.btn-action:hover
					{
						background-color: $color-purple;
					}
			}

		.sidebar
		{
			border-right: 1px solid $color-blue-dark;

			h2
			{
				color: $color-purple;
			}
		}
	}

	.listing
	{
		background-color: $color-grey-light;

		h3
		{
			text-transform: capitalize;
		}

			h3 a:hover
			{
				color: $color-purple;
			}

		.listing-actions
		{

			.btn
			{
				color: $color-blue-dark;

				.fa {
					color: $color-purple;
				}

				&:hover {
					color: #ffffff;
					background: $color-purple;

					.fa {
						color: #ffffff;
					}
				}
			}

			.btn-secondary
			{
				color: #ffffff;

				&:hover
				{
					border-color: $color-purple;
				}
			}
		}
	}

	.gallery-image-container {
		h5 {
			color: $color-purple;
		}
	}

	.download-hover {
		&:hover {
			color: white !important;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.nav-subnav
		{
			border: 1px solid transparentize($color-grey-med, 0.7);
		}

		.nav-subnav .nav-item
		{
			border-bottom: 1px solid transparentize($color-grey-med, 0.7);
		}

		.subpage-banner::after
		{
			border-right: 10px solid $color-blue-dark;
		}

		.swiper-text .title
		{
			font-size: 1rem;
			font-weight: 300;
			line-height: 1.8rem;
		}



		.swiper-text .subtitle
		{
			font-size: 1rem;
			font-weight: 600;
			line-height: 1.6rem;

			color: #22242A;
		}

	}

	/*=========================================================================*/

	/* [Section Styles] */

	.page-sections {
		.section {

			.btn.btn-outline-dark {
				color: $color-grey-med;
				border-color: $color-grey-med;

				&:hover {
					color: #fff;
					background-color: $color-grey-med;
				}
			}

			&.section-grey {
				background-color: $color-grey-light;
			}

			&.section-light-blue {
				background-color: $color-blue-light;
			}

			&.section-blue {
				background-color: $color-blue-dark;
				color: #fff;

				h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
					color: #fff;
				}

				.btn.btn-outline-dark {
					color: #fff;
					border-color: #fff;

					&:hover {
						color: $color-blue-dark;
						background-color: #fff;
					}
				}
			}

			&.section-light-purple {
				background-color: $color-purple-light;
			}

			&.section-purple {
				background-color: $color-purple;
				color: #fff;

				h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
					color: #fff;
				}

				.btn.btn-outline-dark {
					color: #fff;
					border-color: #fff;

					&:hover {
						color: $color-purple;
						background-color: #fff;
					}
				}
			}

			&.section-bg-image {
				.btn.btn-outline-dark {
					&:hover {
						color: $color-grey-med;
					}
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */


		.footer-action::after
		{
			background-color: transparentize($color-blue-dark, 0.2);
		}

			.footer-action .btn-secondary:hover,
			.footer-action .btn-secondary:focus
			{
				background-color: $color-purple;
				border-color: $color-purple;
			}

			.footer-action .fa
			{
				color: $color-purple;
			}

	.footer
	{
		text-transform: capitalize;
	}

		/*.footer::after
		{
			background-image: url('/images/modsel/footer-element.svg');
			background-repeat: no-repeat;
		}*/

	.footer a
	{
		color: $color-grey-med;
	}

		.footer a:hover
		{
			color: $color-blue-dark;
		}

		.footer .f-social a
		{
			color: $color-blue-dark;
		}

		.footer h6 a,
		.footer .h6 a
		{
			color: $color-blue-dark;
		}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}


/*=========================================================================*/

/* [Nav Search Styles] */

.nav-search-results-wrapper {

	.nav-search-results-container {

		.nav-search-result {
			a {
				border: 1px solid $color-grey-light;
				border-top: none;

				&:hover {
					&::before {
						background: $color-blue-dark;
					}
				}
			}

			&.nav-search-page-result {
				background: $color-grey-light;

				a {
					border-bottom-color: #ffffff;
				}
			}
		}
	}
}
